import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import './styles/Room.scss';
import { ReactComponent as EndCallIcon } from 'assets/icons/video-call-end-call.svg';
// import { ReactComponent as FullScreenIcon } from 'assets/icons/video-call-full-screen.svg';
import { ReactComponent as WithAudioIcon } from 'assets/icons/video-call-with-audio.svg';
import { ReactComponent as WithVideoIcon } from 'assets/icons/video-call-with-video.svg';
import { ReactComponent as MutedIcon } from 'assets/icons/muted.svg';
import { ReactComponent as CameraOffIcon } from 'assets/icons/camera_off.svg';
import { Participant } from './components';
import PropTypes from 'prop-types';

function gotDevices(mediaDevices) {
    const audioInputs = [];
    const videoInputs = [];

    mediaDevices.forEach(mediaDevice => {
        if (mediaDevice.kind === 'videoinput') {
            videoInputs.push(mediaDevice);
        } else if (mediaDevice.kind === 'audioinput') {
            audioInputs.push(mediaDevice);
        }
    });

    return { audioInputs, videoInputs };
}

const Room = ({ roomName, token, handleLogout }) => {
    const [flag, setFlag] = useState(false);
    const [localVideoTracks, setLocalVideoTracks] = useState([]);
    const [localAudioTracks, setLocalAudioTracks] = useState([]);
    const [remoteVideoTracks, setRemoteVideoTracks] = useState([]);
    const [remoteAudioTracks, setRemoteAudioTracks] = useState([]);
    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]);

    const videoConnect = ({ audioInputs, videoInputs }) => {
        const participantConnected = participant => {
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        };

        const participantDisconnected = participant => {
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
        };

        Video.connect(token, {
            name: roomName,
            audio: Boolean(audioInputs.length),
            video: Boolean(videoInputs.length)
        }).then(room => {
            setRoom(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.participants.forEach(participantConnected);
        }).catch(console.log);
    };

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices()
            .then(gotDevices)
            .then(videoConnect);

        return () => {
            setRoom(currentRoom => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(trackPublication => {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();

                    return null;
                } else {
                    return currentRoom;
                }
            });
        };
    }, [roomName, token]);

    const [remoteParticipant] = participants;
    const [localVideoTrack] = localVideoTracks;
    const [localAudioTrack] = localAudioTracks;

    const handleToggle = (type, action) => {
        switch (type) {
            case 'video': {
                if (localVideoTrack) {
                    localVideoTrack.enable(action);
                }

                break;
            }

            case 'audio': {
                if (localAudioTrack) {
                    localAudioTrack.enable(action);
                }

                break;
            }
        }

        setFlag(!flag);
    };

    return (
        <div className='Room'>
            <div className='Room_controller'>
                {localAudioTrack?.isEnabled
                    ? <WithAudioIcon className='pointer' onClick={() => handleToggle('audio', false)} />
                    : <MutedIcon className='pointer' onClick={() => handleToggle('audio', true)} />}
                {localVideoTrack?.isEnabled
                    ? <WithVideoIcon className='pointer' onClick={() => handleToggle('video', false)} />
                    : <CameraOffIcon className='pointer' onClick={() => handleToggle('video', true)} />}
                <EndCallIcon className='pointer' onClick={handleLogout} />
            </div>
            <div className='Room_video'>
                {remoteParticipant && <Participant
                    videoTracks={remoteVideoTracks}
                    audioTracks={remoteAudioTracks}
                    setVideoTracks={setRemoteVideoTracks}
                    setAudioTracks={setRemoteAudioTracks}
                    key={remoteParticipant.sid}
                    participant={remoteParticipant} />}
            </div>
            <div className='Room_video--side'>
                {room && <Participant
                    videoTracks={localVideoTracks}
                    audioTracks={localAudioTracks}
                    setVideoTracks={setLocalVideoTracks}
                    setAudioTracks={setLocalAudioTracks}
                    key={room.localParticipant.sid}
                    participant={room.localParticipant} />}
            </div>
        </div>
    );
};

Room.propTypes = {
    roomName: PropTypes.string,
    token: PropTypes.string,
    handleLogout: PropTypes.func
};

export default Room;
