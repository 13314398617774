import api, { source } from 'utils/axios';
import { getAuthToken } from 'context/reducers/utils/auth/helpers';

const token = () => ({ headers: { Authorization: 'Bearer ' + getAuthToken() } });

const video = {
    source,
    room: async () => {
        return await api.get('/video-room', token());
    },
    close: async (id) => {
        const route = `/video-room/close/${id}`;

        return await api.get(route, token());
    },
    join: async (id) => {
        const route = `/video-room/join/${id}`;

        return await api.get(route, token());
    },
    create: async id => {
        const route = '/video-room/create';

        return await api.post(route, { thread_id: id }, token());
    }
};

// function generateUrl(params, baseUrl) {
//     const ret = [];

//     for (const key in params) {
//         if (params[key] !== '') {
//             ret.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
//         }
//     }

//     const paramString = ret.join('&');

//     return paramString ? baseUrl + '?' + paramString : baseUrl;
// }

export default video;
