import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Provider, { useStore } from 'context/store';
import { handleAutoLogin } from 'context/reducers/auth';
import routes, { authRoutes } from './routes';
import { ScrollReset, Loading } from './components';
import { renderRoutes } from 'react-router-config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'config/execute';
import 'styles/styles.scss';
import Pusher from 'utils/usePusher';

const APP = () => {
    const [{ auth }, dispatch] = useStore();

    useEffect(() => {
        handleAutoLogin(dispatch);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (auth.profile.role === 'user') {
            document.body.style.setProperty('--color-primary', auth.profile.company?.brand_color ?? 'inherit');
            document.body.style.setProperty('--font-primary', '"Raleway", sans-serif');
        }
    });

    return auth.isAppReady
        ? (
            <BrowserRouter>
                <ScrollReset />
                <Pusher {...auth} />
                {renderRoutes(auth.isAuthed ? authRoutes[auth.profile.role] : routes(`${auth.profile.company ? auth.profile.company.slug + '/' : ''}${auth.profile.role || ''}`))}
            </BrowserRouter>
        )
        : <Loading />;
};

render(
    <Provider>
        <HelmetProvider>
            <ToastContainer
                position='bottom-left'
                autoClose={5000}
                draggable={true} />
            <APP />
        </HelmetProvider>
    </Provider>,
    document.getElementById('root')
);
