import { STORAGEKEY_APP_LANGUAGE, APP_LANGUAGE_DEFAULT } from 'config/constants';

/**
 * Get app language
 * @returns {String}
 */
export function getAppLanguage() {
    return localStorage.getItem(STORAGEKEY_APP_LANGUAGE) || APP_LANGUAGE_DEFAULT;
}

/**
 * Set app language
 * @param {String} language
 */
export function saveAppLanguage(language) {
    localStorage.setItem(STORAGEKEY_APP_LANGUAGE, language);
}
