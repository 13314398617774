import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, LineChart, Line, CartesianGrid } from 'recharts';

function DOT(props) {
    const { cx, cy, r, value, max } = props;

    if (value === max) {
        return (
            <svg>
                <title>{max}</title>
                <defs>
                    <filter
                        id='dropshadow'
                        filterUnits='userSpaceOnUse'>
                        <feGaussianBlur
                            in='SourceAlpha'
                            stdDeviation='3' />
                        <feOffset
                            dx='0'
                            dy='0'
                            result='offsetblur' />
                        <feMerge>
                            <feMergeNode />
                            <feMergeNode in='SourceGraphic' />
                        </feMerge>
                    </filter>
                </defs>
                <circle cx={cx} cy={cy} r={r + 3}
                    stroke='#FFF'
                    strokeWidth='4'
                    fill='var(--color-primary)'
                    filter='url(#dropshadow)' />
            </svg>);
    } else if (!max) {
        return (
            <svg>
                <defs>
                    <filter
                        id='dropshadow'
                        filterUnits='userSpaceOnUse'>
                        <feGaussianBlur
                            in='SourceAlpha'
                            stdDeviation='3' />
                        <feOffset
                            dx='0'
                            dy='0'
                            result='offsetblur' />
                        <feMerge>
                            <feMergeNode />
                            <feMergeNode in='SourceGraphic' />
                        </feMerge>
                    </filter>
                </defs>
                <circle cx={cx} cy={cy} r={r + 1}
                    stroke='#FFF'
                    strokeWidth='1'
                    fill='var(--color-primary)' />
            </svg>);
    }

    return false;
}

DOT.propTypes = {
    cx: PropTypes.number,
    cy: PropTypes.number,
    r: PropTypes.number,
    value: PropTypes.number,
    max: PropTypes.number,
    color: PropTypes.string
};

const Chart = ({
    data: dataProp,
    dot = false,
    strokeWidth = 5,
    cartesian = [false, false],
    strokeDasharray,
    horizontalPoints,
    verticalPoints,
    height
}) => {
    const max = Math.max(...dataProp.map(point => point.value));

    const getEvenly = (arr, data) => {
        const percentages = 100 / data.length;
        let curr = 0;
        const ret = [];

        while (curr < 100) {
            ret.push(curr + '%');
            curr += percentages;
        }

        ret.push('100%');

        return ret;
    };

    verticalPoints = verticalPoints instanceof Array ? verticalPoints : getEvenly(verticalPoints, dataProp);

    if (dot === 'max') {
        dot = <DOT max={max} />;
    } else if (dot) {
        dot = <DOT />;
    }

    return (
        <ResponsiveContainer height={height}>
            <LineChart data={dataProp} margin={{ right: 15, left: 15, top: 15, bottom: 15 }}>
                <CartesianGrid
                    verticalPoints={verticalPoints}
                    horizontalPoints={horizontalPoints}
                    vertical={cartesian[0]}
                    horizontal={cartesian[1]}
                    stroke='#cbcfd4'
                    strokeDasharray={strokeDasharray} />
                <Line
                    type='monotone'
                    dataKey='value'
                    stroke='var(--color-primary)'
                    strokeWidth={strokeWidth}
                    dot={dot} />
            </LineChart>
        </ResponsiveContainer>
    );
};

Chart.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
    dot: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    strokeWidth: PropTypes.number,
    cartesian: PropTypes.array,
    strokeDasharray: PropTypes.string,
    horizontalPoints: PropTypes.array,
    verticalPoints: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    height: PropTypes.number
};

Chart.defaultProps = {
    data: [],
    labels: []
};

export default Chart;
