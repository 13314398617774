import React from 'react';
import PropTypes from 'prop-types';
import { RadialBarChart, PolarAngleAxis, RadialBar } from 'recharts';

const Chart = ({ className, label, title, value, color, size, total }) => {
    const circleSize = size ?? 75;

    return (
        <div className={className}>
            <RadialBarChart
                width={circleSize}
                height={circleSize}
                innerRadius={30}
                outerRadius={45}
                barSize={10}
                data={[{ value }]}
                startAngle={90}
                endAngle={-270} >
                <PolarAngleAxis
                    type='number'
                    domain={[0, total]}
                    angleAxisId={0}
                    tick={false} />
                <RadialBar
                    background={{ fill: '#cbcfd4' }}
                    clockWise={true}
                    dataKey='value'
                    cornerRadius={circleSize / 2}
                    fill={color} />
            </RadialBarChart>
            <label style={{ color }}>{label}</label>
            <p>{title}</p>
        </div>
    );
};

Chart.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    total: PropTypes.number,
    value: PropTypes.number.isRequired,
    color: PropTypes.string,
    size: PropTypes.number
};

Chart.defaultProps = {
    value: 0,
    total: 100,
    color: '#99a8dc'
};

export default Chart;
