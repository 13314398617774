import axios from 'axios';
import { getAuthToken } from 'context/reducers/utils/auth/helpers';
import { API_URL } from '../config/constants';

export const { source } = axios.CancelToken;
export const { all } = axios;
export default axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    },
    withCredentials: true
});

const HEADERS = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, Authorization, X-Request-With'
};

export const convertParam = (params) => {
    if (params) {
        return '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }

    return '';
};

export const convertQueryString = (query) => {
    return Object.fromEntries(query);
};

export function get(endpoint) {
    try {
        return axios.get(API_URL + endpoint, {
            headers: { ...HEADERS, Authorization: `Bearer ${getAuthToken()}` }
        });
    } catch (e) {
        return e;
    }
}

export function post(endpoint, data) {
    try {
        return axios.post(API_URL + endpoint, data, {
            headers: { ...HEADERS, Authorization: `Bearer ${getAuthToken()}` }
        });
    } catch (e) {
        return e;
    }
}

export function patch(endpoint, data) {
    try {
        return axios.patch(API_URL + endpoint, data, {
            headers: { ...HEADERS, Authorization: `Bearer ${getAuthToken()}` }
        });
    } catch (e) {
        return e;
    }
}

export function del(endpoint) {
    try {
        return axios.delete(API_URL + endpoint, {
            headers: { ...HEADERS, Authorization: `Bearer ${getAuthToken()}` }
        });
    } catch (e) {
        return e;
    }
}
