import React, { Suspense, useState, useContext } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import './syles/Dashboard.scss';

import { NavBar } from './components';
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard-icon.svg';
import { ReactComponent as Coaches } from '../../assets/icons/coaches-icon.svg';
import { ReactComponent as Messages } from '../../assets/icons/message-icon.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-check.svg';
import { Context } from '../../context/store';
import { ROLE } from '../../config/constants';
import UserNavBar from './components/NavBar/UserNavBar';
import ProfileIcon from 'assets/icons/profile.svg';

const Menus = ({ name, avatar }) => ([
    {
        name: 'Dashboard',
        icon: <DashboardIcon />,
        path: '/dashboard'
    },
    {
        name: 'Coaches',
        icon: <Coaches />,
        path: '/coach'
    },
    {
        name: 'Messages',
        icon: <Messages />,
        path: '/messages'
    },
    {
        name: 'My Profile',
        icon: <img alt={name} src={avatar ?? ProfileIcon} />,
        path: '/account'
    }
]);

const CoachMenus = ({ name, avatar }) => ([
    {
        name: 'Dashboard',
        icon: <DashboardIcon />,
        path: '/dashboard'
    },
    {
        name: 'Availability',
        icon: <CalendarIcon />,
        path: '/availability'
    },
    {
        name: 'Messages',
        icon: <Messages />,
        path: '/messages'
    },
    {
        name: 'My Profile',
        icon: <img alt={name} src={avatar ?? ProfileIcon} />,
        path: '/account'
    }
]);

const MenusCompany = ({ name, avatar }) => ([
    {
        name: 'Dashboard',
        icon: <DashboardIcon />,
        path: '/dashboard'
    },
    {
        name: 'Employees',
        icon: <Coaches />,
        path: '/employees'
    },
    {
        name: 'My Profile',
        icon: <img alt={name} src={avatar ?? ProfileIcon} />,
        path: '/profile'
    }
]);

const Dashboard = ({ route, history }) => {
    const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
    const [{ auth }] = useContext(Context);

    const handleNavBarMobileClose = () => {
        setOpenNavBarMobile(false);
    };

    const getMenu = (role) => {
        switch (role) {
            case ROLE.COMPANY:
                return MenusCompany({ name: auth.profile.company_owned?.name, avatar: auth.profile.company_owned?.avatar_logo });
            case ROLE.COACH:
                return CoachMenus(auth.profile);
            default:
                return Menus(auth.profile);
        }
    };

    return (
        <div className={'Dashboard-layout'}>
            <div className='Dashboard-layout_Drawer'>
                {auth.profile?.role === ROLE.USER
                    ? (<UserNavBar
                        onMobileClose={handleNavBarMobileClose}
                        openMobile={openNavBarMobile}
                        menus={getMenu(auth.profile?.role)}
                        history={history} />)
                    : (<NavBar
                        onMobileClose={handleNavBarMobileClose}
                        openMobile={openNavBarMobile}
                        menus={getMenu(auth.profile?.role)}
                        history={history} />)}
            </div>
            <main className='Dashboard-layout_Container'>
                {/* <TopBar onOpenNavBarMobile={handleNavBarMobileOpen} /> */}
                <div className='Dashboard-layout_Content'>
                    <Suspense fallback={<div />}>
                        {renderRoutes(route.routes)}
                    </Suspense>
                </div>
            </main>
        </div>
    );
};

Menus.propTypes = CoachMenus.propTypes = MenusCompany.propTypes = {
    name: PropTypes.string,
    avatar: PropTypes.string
};

Dashboard.propTypes = {
    route: PropTypes.object,
    history: PropTypes.object
};

export default Dashboard;
