import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Dialog.scss';

export default function Dialog(props) {
    const { open, onClose, children, className, height } = props;

    useEffect(() => {
        const esc = event => event.keyCode === 27 && onClose(event);

        document.addEventListener('keydown', esc, false);

        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.removeEventListener('keydown', esc, false);
        };
    }, [open, onClose]);

    return open
        ? (<div className={`Dialog ${className}`} style={{ height }}>
            <div className='backdrop-root' onClick={onClose}>
                <div onClick={e => e.stopPropagation()} className='Dialog-container Dialog-scrollPaper'>
                    {children}
                </div>
            </div>
        </div>)
        : null;
}

Dialog.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    height: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool
};

Dialog.defaultProps = {
    open: false,
    onClose: () => { }
};
