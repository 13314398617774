import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import '../../syles/Dashboard.scss';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout-icon.svg';
import { useStore } from 'context/store';
import { handleLogout } from 'context/reducers/auth';

const UserNavBar = props => {
    const { openMobile, onMobileClose, className, menus, history, ...rest } = props;
    const path = history.location.pathname;
    const [{ auth: { profile } }, dispatch] = useStore();

    const logout = (event) => {
        event.preventDefault();
        handleLogout(true)(dispatch);
    };

    const navbarContent = (
        <div className='Dashboard-layout_Drawer-white'>
            <div className='Dashboard-layout_Drawer-logo'>
                <img alt='logo' src={profile.company?.logo_for_end_user} />
            </div>
            <div className='Dashboard-layout_Drawer-menu'>
                {menus.map(item => {
                    let active = false;
                    const [currentPath] = path.split('/').filter(data => data !== '');
                    const [itemPath] = item.path.split('/').filter(data => data !== '');

                    if (currentPath === itemPath) {
                        active = true;
                    }

                    return (
                        <div
                            key={item.name}
                            className={`Dashboard-layout_Drawer-menu-item ${active && '-active'}`}
                            onClick={() => history.push(item.path)}>
                            {item.icon}
                            {active && (
                                <span>
                                    {item.name}
                                </span>
                            )}
                        </div>
                    );
                })}
            </div>
            <div className='Dashboard-layout_Drawer-logout'>
                <div className='Dashboard-layout_Drawer-logout-button' onClick={logout}>
                    <LogoutIcon />
                </div>
            </div>
        </div>
    );

    return (
        <Fragment>
            <div
                {...rest}
                className='Dashboard-layout_Drawer'>
                {navbarContent}
            </div>
        </Fragment>
    );
};

UserNavBar.propTypes = {
    className: PropTypes.string,
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
    menus: PropTypes.array,
    history: PropTypes.object
};

export default UserNavBar;
