import api, { source } from 'utils/axios';
import { getAuthToken } from 'context/reducers/utils/auth/helpers';

const token = () => ({ headers: { Authorization: 'Bearer ' + getAuthToken() } });

const auth = {
    source,
    login: async (values) => {
        return await api.post('/login', values);
    },
    register: async (values) => {
        return await api.post('/register-coach', values);
    },
    checkEmail: async (email) => {
        return await api.post('/check/email', { email });
    },
    me: async (token) => {
        return await api.get('/me', { headers: { Authorization: 'Bearer ' + token } });
    },
    update: async values => {
        const route = '/users/' + values.id;

        return await api.post(route, values, token());
    },
    updateCompany: async values => {
        const route = '/companies/' + values.id;

        return await api.post(route, values, token());
    }
};

export default auth;
