import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import '../../syles/Dashboard.scss';
import './styles/NavBar.scss';
import Logo from 'assets/others/logo.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout-icon.svg';
import { useStore } from 'context/store';
import { handleLogout } from 'context/reducers/auth';

const NavBar = props => {
    const { openMobile, onMobileClose, className, menus, history, ...rest } = props;
    const path = history.location.pathname;
    const [, dispatch] = useStore();

    const logout = (event) => {
        event.preventDefault();
        handleLogout(true)(dispatch);
    };

    const navbarContent = (
        <div className='NavBar'>
            <div className='NavBar__Container'>
                <div className='NavBar__Container-logo'>
                    <img alt='logo' src={Logo} />
                </div>
                <div className='NavBar__Container-menu'>
                    {menus.map(item => {
                        let active = false;

                        const [currentPath] = path.split('/').filter(data => data !== '');
                        const [itemPath] = item.path.split('/').filter(data => data !== '');

                        if (currentPath === itemPath) {
                            active = true;
                        }

                        return (
                            <div
                                key={item.name}
                                className={`NavBar__Container-menu-item ${active && '-active'}`}
                                onClick={() => history.push(item.path)}>
                                {item.icon}
                            </div>
                        );
                    })}
                </div>
                <div className='NavBar__Container-logout'>
                    <div className='NavBar__Container-logout-button' onClick={logout}>
                        <LogoutIcon />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Fragment>
            <div
                {...rest}
                className='Dashboard-layout_Drawer'>
                {navbarContent}
            </div>
        </Fragment>
    );
};

NavBar.propTypes = {
    className: PropTypes.string,
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
    menus: PropTypes.array,
    history: PropTypes.object
};

export default NavBar;
