/* eslint-disable no-undef */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import Video from 'services/video';
import { Room } from './components';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import useRouter from 'utils/useRouter';

const VideoCall = ({ onClose, initRoom }) => {
    const roomId = useRef();
    const closeVideoWithoutMessage = useRef();
    const [room, setRoom] = useState(initRoom);
    const { match: { params } } = useRouter();

    useEffect(() => {
        getOrCreateRoom();

        const closeWithEvent = () => {
            roomId.current = null;
            onClose && onClose();
        };

        window.addEventListener('VideoRoomCloseEvent', closeWithEvent);

        return () => {
            window.removeEventListener('VideoRoomCloseEvent', closeWithEvent);
            roomId.current && Video.close(roomId.current).then(() => {
                !closeVideoWithoutMessage.current && toast.warning('Please know that if you change the screen, the call will be disconnected.');
            });
        };
    }, [params.id]);

    const getOrCreateRoom = useCallback(async () => {
        try {
            let response = room || await Video.room().then(({ data = [] }) => data.find(el => el.thread_id === Number(params.id)));

            if (!response) {
                response = await Video.create(Number(params.id)).then(({ data }) => data);
            }

            const { data } = await Video.join(response.id);

            roomId.current = response.id;
            setRoom(data);
        } catch (err) {
            console.log(err);
        }
    }, [room]);

    const handleLogout = useCallback(event => {
        if (onClose) {
            onClose();
            closeVideoWithoutMessage.current = true;
        }
    }, []);

    return room
        ? <Room roomName={room.name} token={room.accessToken} handleLogout={handleLogout} />
        : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <strong>Calling...</strong>
        </div>;
};

VideoCall.propTypes = {
    onClose: PropTypes.func,
    initRoom: PropTypes.object
};

export default VideoCall;
