import { notificationType } from 'config/constants';

export const newThreadMessageEvent = threadId => ({
    type: notificationType.NewThreadMessageEvent,
    threadId
});

function Auth(state = null, { type, threadId }) {
    switch (type) {
        case notificationType.NewThreadMessageEvent:
            return threadId;
        default:
            return state;
    }
}

export default Auth;
