import { STORAGEKEY_AUTH_TOKEN } from 'config/constants';

/**
 * Save login token
 * @param {String} token
 * @param {Boolean} isRemember
 */
export function setAuthToken(token, isRemember = true) {
    (isRemember ? localStorage : sessionStorage).setItem(STORAGEKEY_AUTH_TOKEN, token);
}

/**
 * Get login token
 * @returns {String}
 */
export function getAuthToken() {
    return localStorage.getItem(STORAGEKEY_AUTH_TOKEN) ||
        sessionStorage.getItem(STORAGEKEY_AUTH_TOKEN);
}

/**
 * Delete login token
 */
export function deleteAuthToken() {
    localStorage.removeItem(STORAGEKEY_AUTH_TOKEN);
    sessionStorage.removeItem(STORAGEKEY_AUTH_TOKEN);
}
