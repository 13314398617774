import React from 'react';
import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg';

const Loading = () => {
    return (
        <div style={{ color: '#e63939', width: '30vw', height: '100%', display: 'flex', margin: 'auto' }}>
            <LoadingIcon />
        </div>
    );
};

export default Loading;
