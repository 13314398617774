import React, { Suspense, useContext } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { Loading } from 'components';
import { Context } from '../../context/store';

const Auth = ({ route }) => {
    const [{ auth }] = useContext(Context);

    return auth.isAuthenticating
        ? <Loading />
        : (
            <main style={{ height: '100%' }}>
                <Suspense fallback={<div />}>
                    {renderRoutes(route.routes)}
                </Suspense>
            </main>
        );
};

Auth.propTypes = {
    route: PropTypes.object
};

export default Auth;
