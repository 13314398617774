/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';

const routes = role => ([
    {
        path: '/',
        exact: true,
        component: () => <Redirect to={`/auth/${role || 'user'}`} />
    },
    {
        route: '*',
        component: AuthLayout,
        routes: [
            {
                path: '/auth/coach',
                exact: true,
                component: lazy(() => import('views/Coach/Login'))
            },
            {
                path: '/auth/coach/forgot/:token?',
                exact: true,
                component: lazy(() => import('views/Forgot'))
            },
            {
                path: '/auth/coach/register',
                exact: true,
                component: lazy(() => import('views/Coach/Register'))
            },
            {
                path: '/auth/:slug?/user',
                exact: true,
                component: lazy(() => import('views/User/Login'))
            },
            {
                path: '/auth/:slug?/user/forgot/:token?',
                exact: true,
                component: lazy(() => import('views/User/Forgot'))
            },
            {
                path: '/auth/company',
                exact: true,
                component: lazy(() => import('views/Company/Login'))
            },
            {
                path: '/auth/company/forgot/:token?',
                exact: true,
                component: lazy(() => import('views/Forgot'))
            },
            {
                component: () => <Redirect to={`/auth/${role || 'user'}`} />
            }
        ]
    }
]);

const user = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to='/dashboard' />
    },
    {
        route: '*',
        component: DashboardLayout,
        routes: [
            {
                path: '/dashboard',
                exact: true,
                component: lazy(() => import('views/User/Dashboard'))
            },
            {
                path: '/coach',
                exact: true,
                component: lazy(() => import('views/User/Coaches'))
            },
            {
                path: '/coach/:id/book-consultation',
                exact: true,
                component: lazy(() => import('views/User/Coaches/BookConsult'))
            },
            {
                path: '/coach/:id',
                exact: true,
                component: lazy(() => import('views/User/Coaches/ProfileCoach'))
            },
            {
                path: '/messages/:id?',
                exact: true,
                component: lazy(() => import('views/User/Messages'))
            },
            {
                path: '/account',
                exact: true,
                component: lazy(() => import('views/User/Profile'))
            },
            {
                path: '/account/update',
                exact: true,
                component: lazy(() => import('views/User/Profile/UpdateProfile'))
            },
            {
                component: () => <Redirect to='/' />
            }
        ]
    }
];

const coach = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to='/dashboard' />
    },
    {
        route: '*',
        component: DashboardLayout,
        routes: [
            {
                path: '/dashboard',
                exact: true,
                component: lazy(() => import('views/Coach/Dashboard'))
            },
            {
                path: '/appointment',
                exact: true,
                component: lazy(() => import('views/Coach/Appointment'))
            },
            {
                path: '/earnings',
                exact: true,
                component: lazy(() => import('views/Coach/Earnings'))
            },
            {
                path: '/availability',
                exact: true,
                component: lazy(() => import('views/Coach/Availability'))
            },
            {
                path: '/profile',
                exact: true,
                component: lazy(() => import('views/Coach/Coaches/ProfileCoach'))
            },
            {
                path: '/messages/:id?',
                exact: true,
                component: lazy(() => import('views/Coach/Messages'))
            },
            {
                path: '/book-consultation',
                exact: true,
                component: lazy(() => import('views/Coach/Coaches/BookConsult'))
            },
            {
                path: '/account',
                exact: true,
                component: lazy(() => import('views/Coach/Profile'))
            },
            {
                path: '/account/update',
                exact: true,
                component: lazy(() => import('views/Coach/Profile/UpdateProfile'))
            },
            {
                component: () => <Redirect to='/' />
            }
        ]
    }
];

const company = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to='/dashboard' />
    },
    {
        route: '*',
        component: DashboardLayout,
        routes: [
            {
                path: '/dashboard',
                exact: true,
                component: lazy(() => import('views/Company/Dashboard'))
            },
            {
                path: '/employees',
                exact: true,
                component: lazy(() => import('views/Company/Employees'))
            },
            {
                path: '/employees/:id',
                exact: true,
                component: lazy(() => import('views/Company/EmployeeProfile'))
            },
            {
                path: '/employees/:id/edit',
                exact: true,
                component: lazy(() => import('views/Company/EmployeeEdit'))
            },
            {
                path: '/profile',
                exact: true,
                component: lazy(() => import('views/Company/Profile'))
            },
            {
                path: '/profile/edit',
                exact: true,
                component: lazy(() => import('views/Company/ProfileEdit'))
            },
            {
                component: () => <Redirect to='/' />
            }
        ]
    }
];

export const authRoutes = { user, coach, company };

export default routes;
