import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Popover.scss';

export default function Popover(props) {
    const { title, open, onClose, children, className } = props;

    useEffect(() => {
        const esc = event => event.keyCode === 27 && onClose(event);
        const handleClose = event => open && onClose(event);

        document.addEventListener('keydown', esc, false);
        document.addEventListener('click', handleClose, false);

        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.removeEventListener('keydown', esc, false);
            document.removeEventListener('click', handleClose, false);
        };
    }, [open, onClose]);

    return (
        <div className={`Popover ${className}`}>
            {title}
            {open
                ? <div className='Popover-container' onClick={(e) => e.stopPropagation()}>
                    {children}
                </div>
                : null
            }
        </div>
    );
}

Popover.propTypes = {
    title: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool
};

Popover.defaultProps = {
    open: false,
    onClose: () => { }
};
