import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { useCallback, useEffect, useRef } from 'react';
// import { newVideoRoomEvent, videoRoomCloseEvent } from 'context/reducers/events';
import { PUSHER_KEY, API_URL, notificationType } from 'config/constants';
import { getAuthToken } from 'context/reducers/utils/auth/helpers';
import useRouter from 'utils/useRouter';
import { toast } from 'react-toastify';

window.Pusher = Pusher;

const videoRoomCloseEvent = new Event(notificationType.VideoRoomCloseEvent);
const newVideoRoomEvent = new Event(notificationType.NewVideoRoomEvent);
const newThreadMessageEvent = new Event(notificationType.NewThreadMessageEvent);

function usePusher({ isAuthed, profile }) {
    const { history, location: { pathname } } = useRouter();
    const EchoClient = useRef(null);
    const pathName = useRef(null);

    pathName.current = pathname;

    useEffect(() => {
        return () => {
            if (EchoClient.current && profile.id) {
                EchoClient.current.leaveChannel(`App.Models.User.${profile.id}`);
            }
        };
    }, [profile.id]);

    const setupRealtimeEvents = useCallback(async () => {
        const loginToken = await getAuthToken();

        EchoClient.current && EchoClient.current.connector.pusher.disconnect();

        EchoClient.current = new Echo({
            broadcaster: 'pusher',
            cluster: 'eu',
            authEndpoint: `${API_URL}/broadcasting/auth`,
            key: PUSHER_KEY,
            activityTimeout: 60000,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + loginToken
                }
            },
            encrypted: true
        });

        EchoClient.current.private(`App.Models.User.${profile.id}`)
            .listen(notificationType.NewVideoRoomEvent, event => {
                history.push('/messages/' + event.room.thread_id);
                toast.info(event.caller.name + ' calling', {
                    autoClose: false,
                    onClick: () => {
                        newVideoRoomEvent.data = event;
                        window.dispatchEvent(newVideoRoomEvent);
                    }
                });
            });

        EchoClient.current.private(`App.Models.User.${profile.id}`)
            .listen(notificationType.VideoRoomCloseEvent, () => {
                window.dispatchEvent(videoRoomCloseEvent);
            });

        EchoClient.current.private(`App.Models.User.${profile.id}`)
            .listen(notificationType.NewThreadMessageEvent, event => {
                const path = '/messages/' + event.message.thread_id;

                newThreadMessageEvent.data = event;
                window.dispatchEvent(newThreadMessageEvent);

                if (pathName.current !== path) {
                    toast.success(`${event.from.name}\n${event.message.body}`, {
                        onClick: () => {
                            history.push(path);
                        }
                    });
                }
            });
    }, [profile.id]);

    useEffect(() => {
        isAuthed && setupRealtimeEvents();
    }, [isAuthed, setupRealtimeEvents]);

    return null;
}

export default usePusher;
