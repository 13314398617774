/****
 *
 * window & process.env constants
 *
 */
export const ELOQUENT = window.Eloquent;
export const AXIOS = window._axios;

// see https://github.com/SchwSimon/creatella-react-components#withasynccaller
window._withAsyncCallerAxios = window._BaseAxios;

export const APP_ENV = process.env.REACT_APP_ENV;
export const APP_URL = process.env.REACT_APP_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;

export const notificationType = {
    NewVideoRoomEvent: 'NewVideoRoomEvent',
    VideoRoomCloseEvent: 'VideoRoomCloseEvent',
    NewThreadMessageEvent: 'NewThreadMessageEvent'
};

/****
 *
 * Storage keys
 *
 */
export const STORAGEKEY_AUTH_TOKEN = 'sk-authToken';
export const STORAGEKEY_APP_LANGUAGE = 'sk-appLanguage';

/****
 *
 * Configs
 *
 */
// App default language (also used for the <html /> lang='' attribute)
// see https://developer.paciellogroup.com/blog/2016/06/using-the-html-lang-attribute/
// NOTE: make sure the language key defined here matches the default helmet key
// // see `executors/i18n/helmet`
export const APP_LANGUAGE_DEFAULT = 'en'; // TODO_REPLACE(en | de | fr | etc..)

// App environments
// see .env.production & .env.development
// values must match the values defined in these files
export const APP_ENVS = {
    LOCAL: 'local',
    STAGING: 'staging',
    PRODUCTION: 'production'
};

// SENTRY - see https://docs.sentry.io/error-reporting/configuration/?platform=javascript
export const SENTRY_CONFIG = {
    environment: APP_ENV,
    dsn: ''
};

// WEBFONT config - see https://github.com/typekit/webfontloader
export const WEBFONT_CONFIG = {
    // google: {
    //     families: [
    //         'Poppins:300',
    //         'Poppins:400',
    //         'Poppins:500',
    //         'Poppins:700',
    //         'Poppins:900'
    //     ]
    // }
};

// Social networks config
export const SOCIAL_NETWORK_URLS = {
    FACEBOOK: 'TODO_REPLACE',
    INSTAGRAM: 'TODO_REPLACE',
    TWITTER: 'TODO_REPLACE',
    LINKEDIN: 'TODO_REPLACE'
    // ...
};

export const FACEBOOK_APP_ID = 'TODO_REPLACE';

/****
 *
 * App routing
 *
 */
export const ROUTES = {
    HOME: '/',
    LOGIN: '/login',

    _TESTING: '/testing'
};

export const ROUTE_REDIRECT_AFTER_REGISTRATION = 'TODO_REPLACE(ex.: ROUTES.HOME)';
export const ROUTE_REDIRECT_AFTER_LOGIN = '/';
export const ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE = 'TODO_REPLACE(ex.: ROUTES.HOME)';
export const ROUTE_REDIRECT_FROM_AUTHED_ROUTE = 'TODO_REPLACE(ex.: ROUTES.HOME)';

/****
 *
 * Project config
 *
 */

export const ROLE = {
    COACH: 'coach',
    USER: 'user',
    COMPANY: 'company'
};
