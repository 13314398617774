import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { applyMiddleware } from 'context/middleware';
import reducer from 'context/reducers';

export const Context = createContext();

const combinedReducers = (state = {}, action = {}) => Object.assign({}, ...Object.keys(reducer).map(k => ({ [k]: reducer[k](state[k], action) })));

function Provider({ children }) {
    const [state, dispatch] = useReducer(combinedReducers, combinedReducers({}));

    return (
        <Context.Provider value={[state, applyMiddleware(dispatch)]}>
            {children}
        </Context.Provider>);
}

export default Provider;

Provider.propTypes = {
    children: PropTypes.node
};

export const useStore = () => useContext(Context);
