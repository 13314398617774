import * as Sentry from '@sentry/browser';
import Authenticate from 'services/auth';
import { setAuthToken, getAuthToken, deleteAuthToken } from './utils/auth/helpers';
import {
    ROLE,
    ROUTE_REDIRECT_AFTER_LOGIN,
    ROUTE_REDIRECT_AFTER_REGISTRATION
} from 'config/constants';

const IS_APP_READY = 'IS_APP_READY';

const AUTH_START = 'AUTH_START';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAILED_LOGIN = 'AUTH_FAILED_LOGIN';
const AUTH_FAILED_REGISTRATION = 'AUTH_FAILED_REGISTRATION';
const AUTH_LOGOUT_START = 'AUTH_LOGOUT_START';
const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
const UPDATE_PROFILE = 'UPDATE_PROFILE';
const UPDATE_COMPANY_PROFILE = 'UPDATE_COMPANY_PROFILE';

const TEMP_REGISTER = 'TEMP_REGISTER';

export const appIsReady = () => ({
    type: IS_APP_READY
});

const authStart = () => ({
    type: AUTH_START
});

const authSuccess = (profile) => ({
    type: AUTH_SUCCESS,
    profile
});

const authLogoutStart = () => ({
    type: AUTH_LOGOUT_START
});

const authLogoutSuccess = () => ({
    type: AUTH_LOGOUT_SUCCESS
});

const updateProfileStart = () => ({
    type: UPDATE_PROFILE_START
});

const updateProfile = (profile) => ({
    type: UPDATE_PROFILE,
    profile
});

const updateCompanyProfile = (profile) => ({
    type: UPDATE_COMPANY_PROFILE,
    profile
});

const tempRegister = (data) => ({
    type: TEMP_REGISTER,
    data
});

export function handleLogin({ email, password, company, isRemember, userType, companyId }, redirectRoute) {
    return async (dispatch) => {
        const { data: { access_token: token, user } } = await Authenticate.login({ email, password });

        if (user.user_type !== userType) {
            throw { message: 'You don\'t have access to this account' };
        }

        console.log(company);

        if (userType === 2 && companyId !== user.company?.id) {
            throw { message: 'You don\'t have access to this account' };
        }

        setAuthToken(token, isRemember);
        dispatch(authSuccess(user));

        return redirectRoute || ROUTE_REDIRECT_AFTER_LOGIN;
    };
}

export function handleRegistration(params, redirectRoute) {
    return async (dispatch) => {
        const { data: { access_token: token, user } } = await Authenticate.register(params);

        setAuthToken(token);
        dispatch(authSuccess(user));

        return redirectRoute || ROUTE_REDIRECT_AFTER_REGISTRATION;
    };
}

export async function handleAutoLogin(dispatch) {
    try {
        const token = getAuthToken();

        if (token) {
            dispatch(authStart());

            const { data } = await Authenticate.me(token);

            dispatch(authSuccess(data));
        }
    } catch (err) {
        handleLogout(true)(dispatch);
    } finally {
        dispatch(appIsReady());
    }
}

export function handleLogout(isAutoLogout = false) {
    return async (dispatch) => {
        dispatch(authLogoutStart());

        try {
            // if (getAuthToken()) {
            //     await Authenticate.logout();
            // }
        } catch (err) {
            // logout fails when called from unauthed client
            // therefor when called from invalid login
            // silently catch this error
            // in anyway the failing of this api at this point should not cause any issues
        } finally {
            deleteAuthToken();

            if (isAutoLogout === true) {
                dispatch(authLogoutSuccess());
            } else {
                window.location = window.location.origin;
            }
        }
    };
}

export function handleUpdateUserProfile(values) {
    return async (dispatch) => {
        dispatch(updateProfileStart());

        await Authenticate.update(values);

        dispatch(updateProfile(values));
    };
}

export function handleUpdateCompanyProfile(values) {
    return async (dispatch) => {
        dispatch(updateProfileStart());

        await Authenticate.updateCompany(values);

        dispatch(updateCompanyProfile(values));
    };
}

export function handleTempRegister(data) {
    return async (dispatch) => {
        dispatch(tempRegister(data));
    };
}

const INITIAL_STATE = {
    isAppReady: false,
    isAuthenticating: false,
    isUpdatingProfile: false,
    isAuthed: false,
    isLoggingOut: false,
    profile: {},

    tempRegister: {}
};

function Auth(state = INITIAL_STATE, action) {
    switch (action.type) {
        case IS_APP_READY:
            return {
                ...state,
                isAppReady: true
            };

        case AUTH_START:
            return {
                ...state,
                isAuthenticating: true
            };

        case AUTH_FAILED_LOGIN:
        case AUTH_FAILED_REGISTRATION:
            return {
                ...state,
                isAuthenticating: false
            };

        case AUTH_SUCCESS: {
            const { profile } = action;
            const { id, email } = profile;

            Sentry.configureScope((scope) => {
                scope.setUser({ id, email });
            });

            profile.role = Object.values(ROLE)[profile.user_type - 1];

            return {
                ...state,
                isAuthenticating: false,
                isAuthed: true,
                profile
            };
        }

        case UPDATE_PROFILE_START:
            return {
                ...state,
                isUpdatingProfile: true
            };

        case UPDATE_PROFILE: {
            const { profile } = action;

            return {
                ...state,
                isUpdatingProfile: false,
                profile: {
                    ...state.profile,
                    ...profile
                }
            };
        }

        case UPDATE_COMPANY_PROFILE: {
            const { profile } = action;

            return {
                ...state,
                isUpdatingProfile: false,
                profile: {
                    ...state.profile,
                    company_owned: {
                        ...state.profile.company_owned,
                        ...profile
                    }
                }
            };
        }

        case AUTH_LOGOUT_START:
            return {
                ...state,
                isLoggingOut: true
            };

        case AUTH_LOGOUT_SUCCESS:
            return {
                ...INITIAL_STATE,
                profile: {
                    ...INITIAL_STATE.profile,
                    role: state.profile.role,
                    company: state.profile.company
                },
                isAppReady: true
            };

        case TEMP_REGISTER:
            return {
                ...INITIAL_STATE,
                tempRegister: action.data
            };

        default:
            return state;
    }
}

export default Auth;
