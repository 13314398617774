import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Participant = ({ participant, videoTracks, setVideoTracks, audioTracks, setAudioTracks }) => {
    const videoRef = useRef();
    const audioRef = useRef();

    const trackpubsToTracks = trackMap => Array.from(trackMap.values())
        .map(publication => publication.track)
        .filter(track => track !== null);

    useEffect(() => {
        const trackSubscribed = track => {
            if (track.kind === 'video') {
                setVideoTracks(videoTracks => [...videoTracks, track]);
            } else {
                setAudioTracks(audioTracks => [...audioTracks, track]);
            }
        };

        const trackUnsubscribed = track => {
            if (track.kind === 'video') {
                setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
            } else {
                setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
            }
        };

        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.audioTracks));

        participant.on('trackSubscribed', trackSubscribed);
        participant.on('trackUnsubscribed', trackUnsubscribed);

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
        };
    }, [participant]);

    useEffect(() => {
        const [videoTrack] = videoTracks;

        if (videoTrack) {
            videoTrack.attach(videoRef.current);

            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTracks]);

    useEffect(() => {
        const [audioTrack] = audioTracks;

        if (audioTrack) {
            audioTrack.attach(audioRef.current);

            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);

    return (
        <React.Fragment>
            <video ref={videoRef} autoPlay={true} />
            <audio ref={audioRef} autoPlay={true} />
        </React.Fragment>
    );
};

Participant.propTypes = {
    videoTracks: PropTypes.array,
    audioTracks: PropTypes.array,
    setVideoTracks: PropTypes.func,
    setAudioTracks: PropTypes.func,
    participant: PropTypes.object
};

export default Participant;
